<template>
  <a-tag v-if="isTag" :color="color">{{ grade }}</a-tag>
  <div v-else></div>
</template>

<script>
export default {
  name: 'GradeColor',
  props: {
    grade: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      color: '',
      isTag: false,
    }
  },
  mounted() {
    const { grade } = this
    if (grade !== null) {
      this.isTag = true
      if (grade >= 82 && grade <= 100) {
        this.color = 'green'
      } else if (grade >= 35 && grade <= 81) {
        this.color = 'orange'
      } else if (grade >= 0 && grade <= 34) {
        this.color = 'red'
      }
    }
  },
}
</script>

<style></style>
